import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, lifecycle, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from 'rc-slider';
import * as R from 'ramda';
import 'rc-slider/assets/index.css';

import * as actions from 'actions';
import { getApplicationBuyPoints, requestInProcess, getApplicationSelectedQuote } from 'selectors';
import { applicationPropTypesRequired, applicationBuyPoints } from 'propTypes';
import * as requestTypes from 'constants/requestTypes';
import * as fp from 'utils/ramda';

import * as S from './slider';

const propTypes = {
  ...applicationPropTypesRequired,
  buyPoints: applicationBuyPoints,
  updateBuyPoints: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  sliderDisabled: PropTypes.bool,
};

const defaultProps = {
  buyPoints: {},
  loading: false,
  sliderDisabled: false,
};

function BuyPointsSlider({ application, buyPoints, updateBuyPoints, loading, sliderDisabled }) {
  const boughtPoints = R.prop('boughtPoints', application);

  const [value, setValue] = useState(S.parse(boughtPoints));

  const {
    step,
    maxUpPoints,
    maxDownPoints,
    maxAvailableDownPoints,
    maxAvailableUpPoints,
  } = S.init(buyPoints);

  const marks = S.getSliderMarks(step, maxAvailableDownPoints, maxAvailableUpPoints);
  const leftMarks = S.getLeftMarks(step, maxDownPoints, maxAvailableDownPoints);
  const rightMarks = S.getRightMarks(step, maxAvailableUpPoints, maxUpPoints);
  const gridTemplate = S.getGridTemplate({
    maxAvailableUpPoints,
    maxAvailableDownPoints,
    maxDownPoints,
    maxUpPoints,
  });


  const onChange = (val) => setValue(val);
  const onAfterChange = (val) => {
    if (R.equals(val, S.parse(boughtPoints))) return;
    updateBuyPoints({
      quote: {
        bought_points: val,
      },
    });
  };

  useEffect(() => {
    setValue(S.parse(boughtPoints));
  }, [boughtPoints]);

  if (R.isEmpty(marks)) return null;

  return (
    <SliderStyledContainer gridTemplate={gridTemplate}>
      <div>
        {fp.isDefined(leftMarks) && (
          <Slider
            marks={leftMarks}
            min={maxDownPoints}
            max={maxAvailableDownPoints}
            handle={() => null}
            disabled
            step={step}
          />
        )}
      </div>
      <div className="buy_points_slider_wrap">
        <Slider
          marks={marks}
          min={maxAvailableDownPoints}
          max={maxAvailableUpPoints}
          step={step}
          disabled={loading || sliderDisabled}
          onAfterChange={onAfterChange}
          onChange={onChange}
          value={value}
        />
      </div>
      <div>
        {fp.isDefined(rightMarks) && (
          <Slider
            handle={() => null}
            marks={rightMarks}
            min={maxAvailableUpPoints}
            max={maxUpPoints}
            disabled
            step={step}
          />
        )}
      </div>
    </SliderStyledContainer>

  );
}

const SliderStyledContainer = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};

  & .rc-slider-handle {
    width: 18px;
    height: 18px;
    margin-top: -7px;
  }

  & .buy_points_slider_wrap .rc-slider-dot {
    border-color: #96dbfa;
  }

  & .buy_points_slider_wrap .rc-slider-rail {
    background-color: #abe2fb;
  }
`;


BuyPointsSlider.propTypes = propTypes;
BuyPointsSlider.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const buyPoints = getApplicationBuyPoints(state);
  const loading = requestInProcess(state, requestTypes.QUOTE);
  const selectedQuote = getApplicationSelectedQuote(state);
  const sliderDisabled = R.prop('buyPointsDisabled', selectedQuote);

  return {
    loading,
    buyPoints,
    sliderDisabled,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateLTV: bindActionCreators(actions.discussQuote, dispatch),
    updateBuyPoints: bindActionCreators(actions.buyPoints, dispatch),
    getApplicationBuyPointsGuideline: bindActionCreators(actions.fetchApplicationBuyPointsGuideline, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { application, getApplicationBuyPointsGuideline } = this.props;
      const { token } = application;
      getApplicationBuyPointsGuideline(token);
    },
  }),
  pure,
);

export default enhance(BuyPointsSlider);
