import React from 'react';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import styled from 'styled-components';

import { PageTemplate } from 'components/templates';
import { FormAuthSignIn } from 'components/blocks/Forms/Auth';
import { Link, Title, Subtitle, Wrapper } from 'components/shared';
import { RESET_PASS } from 'constants/routes';

const SignInPage = () => (
  <PageTemplate>
    <Wrapper>
      <StyledTitle>Welcome back to LendingOne!</StyledTitle>
      <StyledSubtitle>
        It seems you already have an account with us. Please enter your username and password below to login.
        <br />
        If you have forgotten your password, please click <Link to={RESET_PASS}>here</Link> to reset it.
      </StyledSubtitle>
      <FormAuthSignIn />
    </Wrapper>
  </PageTemplate>
);

const StyledTitle = styled(Title)`
  margin-top: 30px;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 20px;
`;

export default ScrollToTopOnMount(SignInPage);
