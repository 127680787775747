import React from 'react';
import PropTypes from 'prop-types';

import ConfirmEmailBanner from 'components/ConfirmEmail/Banner';
import OriginalUserBanner from 'components/OriginalUserBanner';

import TemplateBanner from 'components/templates/shared/TemplateBanner';

import { Footer, Header, PrimaryNavigation } from './shared';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const PageTemplate = ({ children }) => [
  <TemplateBanner>
    <ConfirmEmailBanner />
    <OriginalUserBanner />
  </TemplateBanner>,
  <header key="header">
    <Header />
  </header>,
  <PrimaryNavigation key="primaryNavigation" />,
  <section key="content">{children}</section>,
  <footer key="footer">
    <Footer />
  </footer>,
];

PageTemplate.propTypes = propTypes;
export default PageTemplate;
