import * as R from 'ramda';

export function parse(num) {
  if (R.is(String, num)) {
    return parse(parseFloat(num));
  }

  return parseFloat(num.toFixed(2));
}

function parsePoint(step, point) {
  const num = Math.floor(parse(point) / step) * step;
  return parse(num);
}

export function init(data) {
  const defalultData = {
    step: 0.25,
    maxDownPoints: 0,
    maxUpPoints: 0,
    maxAvailableDownPoints: 0,
    maxAvailableUpPoints: 0,
  };

  const _parseStep = R.over(R.lensProp('step'), parse);
  const _parsePoint = R.curry((pointName, _data) => {
    const step = R.prop('step', _data);
    return R.over(R.lensProp(pointName), R.partial(parsePoint, [step]), _data);
  });

  const _checkDownward = (_data) => {
    const maxDownPoints = R.prop('maxDownPoints', _data);
    const maxAvailableDownPoints = R.prop('maxAvailableDownPoints', _data);
    if (maxDownPoints > maxAvailableDownPoints) {
      return { ..._data, maxDownPoints: maxAvailableDownPoints };
    }

    return _data;
  };

  const _checkUpward = (_data) => {
    const maxUpPoints = R.prop('maxUpPoints', _data);
    const maxAvailableUpPoints = R.prop('maxAvailableUpPoints', _data);
    if (maxUpPoints < maxAvailableUpPoints) {
      return { ..._data, maxUpPoints: maxAvailableUpPoints };
    }

    return _data;
  };

  const _negateDownPoints = R.evolve({
    maxDownPoints: R.negate,
    maxAvailableDownPoints: R.negate,
  });

  return R.compose(
    _checkDownward,
    _checkUpward,
    _negateDownPoints,
    _parsePoint('maxAvailableUpPoints'),
    _parsePoint('maxAvailableDownPoints'),
    _parsePoint('maxUpPoints'),
    _parsePoint('maxDownPoints'),
    _parseStep,
    R.mergeRight(defalultData),
  )(data);
}

export function getSliderMarks(step, maxDownPoints, maxUpPoints) {
  function calculate(acc, prev) {
    if (!R.is(Number, prev) || prev > maxUpPoints) return acc;
    const next = parseFloat((prev + step).toFixed(2));
    return calculate({ ...acc, [prev]: prev }, next);
  }

  return calculate({}, maxDownPoints);
}

export const getLeftMarks = (step, maxDownPoints, maxAvailableDownPoints) => R.compose(
  R.omit([maxAvailableDownPoints]),
  getSliderMarks,
)(step, maxDownPoints, maxAvailableDownPoints);

export const getRightMarks = (step, maxAvailableUpPoints, maxUpPoints) => R.compose(
  R.omit([maxAvailableUpPoints]),
  getSliderMarks,
)(step, maxAvailableUpPoints, maxUpPoints);

export const getGridTemplate = ({
  maxDownPoints,
  maxAvailableDownPoints,
  maxAvailableUpPoints,
  maxUpPoints,
} = {}) => {
  const calc = (min, max) => max - min;
  const percent = (t, p) => (p / t) * 100;
  const total = calc(maxDownPoints, maxUpPoints);
  const available = calc(maxAvailableDownPoints, maxAvailableUpPoints);
  const left = calc(maxDownPoints, maxAvailableDownPoints);
  const right = calc(maxAvailableUpPoints, maxUpPoints);
  return `${percent(total, left)}% ${percent(total, available)}% ${percent(total, right)}%`;
};
