import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withRouter } from 'react-router-dom';

import { userDefaultProps, userPropTypes } from 'propTypes';
import { BRIDGE_PATH, FAQS_URL, RENTAL_PATH, SIGN_IN_PATH, BRIDGE_NEW_CONSTRUCTION_PATH } from 'constants/routes';
import { WithUser } from 'hocs';
import { Wrapper } from 'components/shared';

import { Nav, NavList, NavLi, Link } from './style';

const propTypes = {
  ...userPropTypes,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const defaultProps = {
  ...userDefaultProps,
};

function PrimaryNavigation({ location, user, userIsLoading }) {
  const isExistingClientLogin = !user && !userIsLoading;
  const isExactPath = (path) => (path === location.pathname + location.search);

  return (
    <Nav>
      <Wrapper>
        <NavList>
          <NavLi>
            <Link
              to={BRIDGE_PATH}
              activeClassName={isExactPath(BRIDGE_PATH) ? "active" : ""}
            >
              Fix/Flip or Bridge Loan
            </Link>
          </NavLi>

          <NavLi>
            <Link to={RENTAL_PATH} activeClassName="active">
              Rental Loan
            </Link>
          </NavLi>

          <NavLi>
            <Link
              to={BRIDGE_NEW_CONSTRUCTION_PATH}
              activeClassName={isExactPath(BRIDGE_NEW_CONSTRUCTION_PATH) ? "active" : ""}
            >
              New Construction
            </Link>
          </NavLi>

          <NavLi>
            <Link to={FAQS_URL} target="_blank">
              FAQs
            </Link>
          </NavLi>

          {isExistingClientLogin && (
            <NavLi>
              <Link to={{ pathname: SIGN_IN_PATH, state: { from: location } }}>Existing Client Login</Link>
            </NavLi>
          )}
        </NavList>
      </Wrapper>
    </Nav>
  );
}

const enhance = compose(
  withRouter,
  WithUser,
  pure,
);

PrimaryNavigation.propTypes = propTypes;
PrimaryNavigation.defaultProps = defaultProps;

export default enhance(PrimaryNavigation);
