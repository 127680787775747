import React from 'react';
import styled from 'styled-components';
import { compose, pure, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withRouter } from 'react-router-dom';

import { INDEX_PATH, PROFILE_PATH, REFERRAL_PATH } from 'constants/routes';
import { MenuContainer as Menu, MenuDivider, MenuItem } from 'components/shared/menu';

import { WithUser } from 'hocs';
import { userPropTypes, userDefaultProps } from 'propTypes';
import { getPath } from 'utils';

import NavigationLink from 'components/shared/Navigation/shared/Link';

const propTypes = {
  ...userPropTypes,
  onLogoutClick: PropTypes.func.isRequired,
};

const defaultProps = {
  ...userDefaultProps,
};

function NavigationDropdown({ user, contact, onLogoutClick }) {
  return (
    <Downshift>
      {({ closeMenu, isOpen, openMenu }) => {
        const handleClick = isOpen ? closeMenu : openMenu;
        return (
          <div>
            <Container>
              <NavigationLink handleClick={handleClick} dataName="fullName">
                {contact.firstName} {contact.lastName}
              </NavigationLink>
              {isOpen ? (
                <Menu>
                  <MenuItem url={getPath(PROFILE_PATH, { id: user.id })}>Profile</MenuItem>
                  <MenuItem url={getPath(REFERRAL_PATH, { id: user.id })}>Referral Link</MenuItem>
                  <MenuDivider />
                  <MenuItem action={onLogoutClick} dataName="signOut">
                    Sign Out
                  </MenuItem>
                </Menu>
              ) : null}
            </Container>
          </div>
        );
      }}
    </Downshift>
  );
}

const Container = styled.div`
  position: relative;
`;

NavigationDropdown.propTypes = propTypes;
NavigationDropdown.defaultProps = defaultProps;

const enhance = compose(
  withRouter,
  WithUser,
  withHandlers({
    onLogoutClick: ({ history, logout }) => () => {
      logout();
      history.push(INDEX_PATH);
    },
  }),
  pure,
);

export default enhance(NavigationDropdown);
