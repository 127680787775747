import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { find, propEq } from 'ramda';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';

import * as actions from 'actions';

import { requestInProcess } from 'selectors';

import { Link, Paragraph } from 'components/shared';

import * as requestTypes from 'constants/requestTypes';
import { applicationPropTypesRequired } from 'propTypes';
import { getIn, percentFormat, decimalCurrencyFormat } from 'utils';

import { UpdateLTVForm } from 'components/blocks/LeadForm/shared/Qualified/shared';
import { Table, Th, Tr, Td } from 'components/blocks/LeadForm/shared/Qualified/shared/Table';
import BuyPointsSlider from './BuyPointsSlider';

const propTypes = {
  ...applicationPropTypesRequired,
  className: PropTypes.string,
  selectQuote: PropTypes.func.isRequired,
};

const defaultProps = {
  className: null,
  selectedQuote: {},
};

function Quotes({ application, selectedQuote, className, selectQuote, loading }) {
  return (
    <Fragment>
      <UpdateLTVForm />

      <Fragment>
        <QuotesHeader>Rate Quote prior to Completed Application</QuotesHeader>

        <Table className={className}>
          <thead>
            <Tr>
              <Th />
              <Th>Loan Amount</Th>
              <Th>Interest Rate</Th>
              <Th>Buy Up/Down Rate</Th>
              <Th>Final Rate</Th>
              <Th>Term</Th>
              <Th>Origination Fee</Th>
              <Th>Buy Down Fee</Th>
              <Th />
            </Tr>
          </thead>
          <tbody>
            {application.quotes &&
              application.quotes.map((quote, index) => (
                <Tr selected={quote.selected} key={`application-${application.id}-quote-${index}`}>
                  <Td>{quote.product.name}</Td>
                  <Td right>
                    <Strong>{decimalCurrencyFormat(quote.totalAmount)}</Strong>
                  </Td>
                  <Td right>
                    <Strong>{percentFormat(getIn('interestRates.calculatedInterestRate', quote))}</Strong>
                  </Td>
                  <Td right>{loading ? <ContentLoader speed={3} /> : percentFormat(quote.buyUpDownRate)}</Td>
                  <Td right highlight>
                    {loading ? (
                      <ContentLoader speed={3} />
                    ) : (
                      percentFormat(getIn('interestRates.finalBuyPointsInterestRate', quote))
                    )}
                  </Td>
                  <Td right>
                    <Strong>{quote.term}</Strong>
                  </Td>
                  <Td right>
                    <Strong>
                      {percentFormat(getIn('originationFeePercents.calculatedOriginationFeePercent', quote))}
                    </Strong>
                  </Td>
                  <Td right highlight>
                    {loading ? (
                      <ContentLoader speed={3} />
                    ) : (
                      percentFormat(
                        getIn('boughtFee', quote),
                      )
                    )}
                  </Td>
                  <Td right>
                    {quote.selected ? (
                      <span>Selected</span>
                    ) : (
                      <Link
                        onClick={() =>
                          selectQuote({
                            token: application.token,
                            quote: { ...quote, productId: getIn('product.id', quote) },
                          })
                        }
                      >
                        Select
                      </Link>
                    )}
                  </Td>
                </Tr>
              ))}
          </tbody>
        </Table>

        {application.quotes && selectedQuote.buyPointsViewable && (
          <SliderWrapper>
            <Paragraph style={{ marginBottom: 20 }} center>
              Buy Points
            </Paragraph>

            <BuyPointsSlider application={application} />
          </SliderWrapper>
        )}
      </Fragment>
    </Fragment>
  );
}

const Strong = styled.strong`
  font-weight: bold;
`;

const QuotesHeader = styled.div`
  text-align: center;
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px solid #d5dde9;
`;

const SliderWrapper = styled.div`
  margin: 20px auto 80px;
  max-width: 820px;
`;

Quotes.propTypes = propTypes;
Quotes.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const { application } = props;
  const loading = requestInProcess(state, requestTypes.QUOTE);

  return {
    loading,
    selectedQuote: application.quotes && find(propEq('selected', true), application.quotes),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectQuote: bindActionCreators(actions.selectQuote, dispatch),
    updateLTV: bindActionCreators(actions.discussQuote, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Quotes);
