import styled from 'styled-components';
import media from 'styled-media-query';
import NavLink from 'react-router-dom/NavLink';

const Nav = styled.nav`
  margin-bottom: 20px;
  background: #5f666c;
`;

const NavList = styled.ul`
  ${media.lessThan('medium')`
    margin-left: -40px;
    margin-right: -40px;
  `};
`;

const NavLi = styled.li`
  position: relative;
  display: block;

  ${media.greaterThan('medium')`
    display: inline-block;
    :last-child a:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -10px;
      height: 20px;
      width: 1px;
      border-left: 1px solid #6e7881;
    }
  `};
`;

const Link = styled(NavLink).attrs({})`
  position: relative;
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #fff;
  font-size: 0.85rem;

  &.active {
    color: #fff;
    background: #6e7881;
    &:before {
      display: none;
    }
  }

  &:hover,
  &:focus {
    color: #fff;
    background: #6e7881;
    &:before {
      display: none;
    }
  }

  ${media.greaterThan('medium')`
    padding: 17px 20px;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -1px;
      margin-top: -10px;
      height: 20px;
      width: 1px;
      border-left: 1px solid #6e7881;
    }
  `};
`;

export { Nav, NavList, NavLi, Link };
