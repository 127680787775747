import React from 'react';
import styled from 'styled-components';

import { Navigation, Wrapper } from 'components/shared';
import { APP_NAME } from 'constants/views';

const FooterContainer = styled.div`
  padding: 1.25rem 0 3.75rem;
  width: 100%;
  border-top: 0.0625rem solid #d5dde9;
`;

const FooterCopyright = styled.p`
  color: #77808b;
  letter-spacing: 0;
  line-height: 1.625rem;
  text-align: center;
`;

const FooterAddress = styled.p`
  padding-top: 0.5rem;
  color: #77808b;
  letter-spacing: 0;
  line-height: 1.375rem;
  font-size: 0.875rem;
  text-align: center;
`;

const FooterNavigation = styled.div`
  text-align: center;
  padding-top: 1.5625rem;
`;

function Footer(props) {
  return (
    <FooterContainer {...props}>
      <Wrapper>
        <FooterCopyright>
          &copy; 2015 &mdash; {new Date().getFullYear()} {APP_NAME}, LLC. All Rights Reserved.
        </FooterCopyright>
        <FooterAddress>777 Yamato Road, Suite 510, Boca Raton, FL 33431</FooterAddress>
        <FooterNavigation>
          <Navigation footer />
        </FooterNavigation>
      </Wrapper>
    </FooterContainer>
  );
}

export default Footer;
