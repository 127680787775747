import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import styled, { injectGlobal, ThemeProvider } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Helmet from 'react-helmet';
import reset from 'styled-reset';
import { ToastContainer } from 'react-toastify';
import * as R from 'ramda';

import * as actions from 'actions';
import { history } from 'store/configureStore';
import { getIsInIframe, getAuthStatus } from 'selectors';

import PrivateRoute from 'components/PrivateRoute';
import theme from 'components/themes/default';

import {
  APPLICATION_AUTH_PATH,
  LOGIN_AS_PATH,
  APPLICATION_PATH,
  APPLICATION_ROOT_PATH,
  BRIDGE_PATH,
  CONFIRMATION_EMAIL_PATH,
  DRAW_REQUESTS_PATH,
  EDIT_PASSWORD_PATH,
  INDEX_PATH,
  MY_APPLICATIONS_PATH,
  PROFILE_PATH,
  REFERRAL_PATH,
  RENTAL_PATH,
  RESET_PASS,
  RESET_SET_PASS,
  SIGN_IN_PATH,
} from 'constants/routes';
import {
  Application,
  ApplicationAuth,
  ApplicationRoot,
  BridgePage,
  ConfirmEmailPage,
  LoginAsAuth,
  DrawRequestsPage,
  EditPasswordPage,
  HomePage,
  Iframe,
  MePage,
  MyApplicationsPage,
  NotFoundPage,
  ReferralLinkPage,
  RentalPage,
  ResetPassPage,
  ResetSetPassPage,
  SignInPage,
} from 'components';
import { LockOutListener } from 'components/blocks';
import { getAuthData, getRemainingTime, removeAuthData } from 'utils';
import rollbarErrorTracking from 'utils/rollbar';

const propTypes = {
  authStatus: PropTypes.bool.isRequired,
  isInIframe: PropTypes.bool.isRequired,
};

class LendingoneApp extends PureComponent {
  constructor(props) {
    super(props);
    resetStyles();
  }

  componentDidCatch(error, errorInfo) {
    const rollbar = rollbarErrorTracking('6f9ae2263da24b03b646524e5d5f1922');
    rollbar.logErrorInfo(errorInfo);
    rollbar.logErrorInRollbar(error);
  }

  render() {
    const { isInIframe, authStatus } = this.props;
    return (
      <Container>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          titleTemplate="LendingOne - %s"
          title="Direct Private Real Estate Lender for Fix and Flip and Rental Loans"
          link={[
            {
              rel: 'icon',
              type: 'image/png',
              href: `${process.env.PUBLIC_URL}/lendingone/favicon.ico`,
            },
          ]}
          script={[
            {
              type: 'text/javascript',
              src: `${process.env.PUBLIC_URL}/lendingone/hotjar.js`,
            },
          ]}
          meta={[
            {
              name: 'description',
              content:
                'Great Rates and Leverage for Fix and Flip and 30 Yr Rental Loans. LendingOne has the best customer service! Get a rate in 2 Minutes!',
            },
          ]}
        />

        <ThemeProvider theme={theme}>
          <Fragment>
            <ConnectedRouter history={history}>
              <Switch>
                {isInIframe && <Route path={INDEX_PATH} component={Iframe} />}
                <Route path={INDEX_PATH} component={HomePage} exact />
                <Route path={BRIDGE_PATH} component={BridgePage} exact />
                <Route path={RENTAL_PATH} component={RentalPage} exact />
                <Route path={APPLICATION_AUTH_PATH} component={ApplicationAuth} />
                <Route path={SIGN_IN_PATH} component={SignInPage} exact />
                <Route path={RESET_PASS} component={ResetPassPage} exact />
                <Route path={RESET_SET_PASS} component={ResetSetPassPage} exact />
                <Route path={CONFIRMATION_EMAIL_PATH} component={ConfirmEmailPage} />
                <Route path={LOGIN_AS_PATH} component={LoginAsAuth} />

                <PrivateRoute path={APPLICATION_ROOT_PATH} component={ApplicationRoot} />
                <PrivateRoute path={EDIT_PASSWORD_PATH} component={EditPasswordPage} exact />

                <PrivateRoute path={APPLICATION_PATH} component={Application} />
                <PrivateRoute path={MY_APPLICATIONS_PATH} component={MyApplicationsPage} />

                <PrivateRoute path={PROFILE_PATH} component={MePage} />
                <PrivateRoute path={REFERRAL_PATH} component={ReferralLinkPage} />
                <PrivateRoute path={DRAW_REQUESTS_PATH} component={DrawRequestsPage} />

                <Route component={NotFoundPage} />
              </Switch>
            </ConnectedRouter>
            {authStatus && <LockOutListener />}
          </Fragment>
        </ThemeProvider>
        <ToastContainer />
      </Container>
    );
  }
}

const resetStyles = () => injectGlobal`
  ${reset}

  html{
    overflow-x: hidden;
    position: relative;
    height: 100%;
  }

  #root{
    position: relative;
    height: 100%;
  }

  body {
    position: relative;
    height: 100%;
    margin: 0;
    left: 0;
    text-align: left;
    line-height: 1.42857;
    font-size: 16px;
    font-family: "Open Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
    color: #504f4d;
    background: #FFF;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

LendingoneApp.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    isInIframe: getIsInIframe(state),
    authStatus: getAuthStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRefs: bindActionCreators(actions.fetchRefs, dispatch),
    initialAuthRefrash: bindActionCreators(actions.initialAuthRefrash, dispatch),
    setSearchParams: bindActionCreators(actions.setSearchParams, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    initialLogin: ({ initialAuthRefrash }) => () => {
      const data = getAuthData();
      const remainingTime = getRemainingTime();
      if (R.isNil(data)) return;
      if (R.isEmpty(data)) return;
      if (remainingTime < 0) {
        removeAuthData();
        return;
      }
      initialAuthRefrash(data);
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      this.props.fetchRefs();
      this.props.setSearchParams();
      this.props.initialLogin();
    },
  }),
  pure,
);

export default enhance(LendingoneApp);
